import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const GTMStrategyGuide = () => {
  return (
    <div className="">
      <h1 className="course-title">How to Answer Go-to-Market (GTM) Strategy Questions</h1>

      <p className="course-intro">
        Go-to-market (GTM) strategy questions test your ability to launch a product successfully. Some example questions include:
      </p>
      <ul className="bullet-list">
        <li>"How would you launch a Uber subscription Uber One?"</li>
        <li>"How would you launch a standalone ticket booking app for Zomato (District)?"</li>
        <li>"What would be your launch strategy for Meta Glass?"</li>
      </ul>

      <p className="course-intro">
        These questions require you to consider standard strategic concerns—like company mission, product strategy, and competitive landscape—but also dive deeper into your user base. You'll need to think carefully about who you're targeting and how to reach them.
      </p>

      <p className="course-intro">
        Ask yourself:
      </p>
      <ul className="bullet-list">
        <li>Is the goal to increase engagement among existing users, or to attract new users?</li>
        <li>How do I reach my target audience?</li>
        <li>How do I convince them that this product is valuable?</li>
        <li>What resources and support will I need to execute the launch?</li>
      </ul>
      <p className="course-intro">
        These are the key areas to explore when answering a GTM question.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Step 1: Define the Landscape</h2>
        <p>
          Begin by understanding why the product is being launched and who it's targeting. You'll need to clarify the goals behind the launch, the target users, and how this product fits within the company's overall strategy.
        </p>
        <p>
          When asking clarifying questions, think about:
        </p>
        <ul className="bullet-list">
          <li>What does the product do?</li>
          <li>How does it fit into the company's existing product portfolio?</li>
          <li>Why is the product being launched?</li>
        </ul>
        <p>
          For example, if you're asked to launch a new feature for Instagram, you might ask, "How does this feature align with Instagram's focus on short-form content?" or "What's the goal behind launching this feature—are we trying to drive engagement or attract a new user segment?"
        </p>
        <p>
          Before jumping into the GTM strategy itself, assess the competitive landscape. Ask yourself:
        </p>
        <ul className="bullet-list">
          <li>What competitors might influence how this launch goes?</li>
          <li>What are the opportunities or challenges in the market?</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Step 2: Identify a GTM Strategy</h2>
        <p>
          Go-to-market strategies can vary greatly depending on the product and the company's goals. Some examples of common GTM strategies include:
        </p>
        <ul className="bullet-list">
          <li><strong>Inbound strategy:</strong> Using helpful content to attract your target audience.</li>
          <li><strong>Sales-driven strategy:</strong> Leveraging a dedicated sales team, particularly for technical or complex products.</li>
          <li><strong>Demand generation strategy:</strong> Creating buzz and hype around the product to generate excitement and interest.</li>
        </ul>
        <p>
          For any GTM strategy, break it down into two phases:
        </p>
        <ol className="numbered-list">
          <li><strong>Identify a user segment:</strong> Who are the users you want to target? How will you reach them?</li>
          <li><strong>Convince them to try and stick with the product:</strong> What will make these users adopt the product, and how can you ensure long-term engagement?</li>
        </ol>
        <p>
          After outlining these phases, prioritize the approach that makes the most sense given your scenario and the company's resources.
        </p>
      </section>

      <section className="interview-section mb-8">
        <h2 className="section-title text-2xl font-bold mb-4">GTM Example: Launching YouTube Shorts</h2>
        <p className="mb-4">
          Imagine you're asked, "How would you launch YouTube Shorts?"
        </p>
        <p className="mb-4">
          Start with some clarifying questions:
        </p>
        <ul className="bullet-list list-disc pl-6 mb-4">
          <li>"Does YouTube already have a library of short-form videos ready for launch?"</li>
          <li>"Will Shorts be integrated into the main app, or will it be a standalone experience?"</li>
        </ul>
        <p className="mb-4">
          Let's assume the interviewer tells you that YouTube already has a user base creating short-form videos and that Shorts will be integrated into the main YouTube app with its own dedicated section.
        </p>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 1: Define the Landscape</h3>
          <p className="mb-4">
            Before diving into the GTM strategy, assess why YouTube is launching this feature:
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "YouTube's ad-driven model thrives on user engagement and content consumption. With the rise of short-form video platforms like TikTok and Instagram Reels, the audience's attention span has shifted. By launching Shorts, YouTube can capture this trend and prevent users from leaving the platform for competitors. This feature could also provide creators with a new format to diversify their content and reach younger audiences."
          </blockquote>
          <p className="mb-4">
            Next, analyze the competition:
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "Short-form video is a saturated space with established players like TikTok, Instagram Reels, and Snapchat Spotlight. The challenge for YouTube is to stand out in an environment where users are already loyal to their preferred platforms. However, YouTube's edge lies in its massive creator ecosystem, monetization options, and recommendation engine, which could offer creators and viewers a seamless transition into short-form content without leaving the app."
          </blockquote>
        </div>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 2: Identify a GTM Strategy</h3>
          <p className="mb-4">
            Let's define the primary target audience and strategy. Assume the goal is to retain existing YouTube users and attract creators from competing platforms.
          </p>
          <p className="mb-4">
            Primary Target Audience:
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "I'd focus on two segments: (1) existing YouTube viewers, particularly Gen Z users, who consume short-form content on competing platforms, and (2) creators looking for a more monetizable and integrated platform for short-form videos."
          </blockquote>
          <p className="mb-4">
            GTM Approach:
            You can break this into two phases:
          </p>
          <p className="mb-4">
            <strong>Phase 1: Reaching Users</strong>
          </p>
          <p className="mb-4">
            "Since we're targeting existing YouTube viewers, the most efficient way to reach them is by leveraging YouTube's homepage and push notifications. We could dedicate a prominent carousel on the homepage to highlight Shorts and promote trending or popular short-form videos. Additionally, the subscription tab can be redesigned to feature Shorts from creators users already follow. For creators, we could launch an awareness campaign through YouTube Studio, inviting them to explore the Shorts format."
          </p>
          <p className="mb-4">
            <strong>Phase 2: Convincing Users</strong>
          </p>
          <p className="mb-4">
            "To get users to engage with Shorts, we'd emphasize ease of discovery and creator benefits. For viewers, Shorts could autoplay in a continuous vertical feed (similar to TikTok) with personalized recommendations, making it effortless to consume content. For creators, we'd highlight how Shorts could help them grow their audience and earn ad revenue. Offering tools like easy video trimming and integrated music libraries would lower the barrier to entry for new creators."
          </p>
        </div>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 3: Execute and Prioritize</h3>
          <p className="mb-4">
            After defining the GTM strategy, prioritize the key actions to maximize impact:
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "The primary focus should be on making Shorts highly visible within the app. The first step would be to redesign YouTube's interface to create a distinct tab or section for Shorts. Simultaneously, we should enable push notifications for personalized short-form content to drive initial engagement. On the creator side, launching a tutorial or educational campaign about how to use Shorts and monetize them would be critical."
          </blockquote>
        </div>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 4: Evaluate and Recap</h3>
          <p className="mb-4">
            Conclude by addressing potential risks, challenges, and trade-offs:
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "One risk is that users might view Shorts as redundant if they already use TikTok or Instagram for similar content. To counter this, we'd need to leverage YouTube's strength in creator monetization by highlighting how Shorts can earn creators money. Another challenge is balancing long-form content engagement with the new short-form format. We could address this by ensuring that Shorts act as a gateway, funneling users into watching long-form videos by the same creator."
          </blockquote>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title text-2xl font-bold mb-4">Common Pitfalls</h2>
        <ul className="bullet-list list-disc pl-6">
          <li><strong>Set context:</strong> Don't skip the step of understanding the product's goals and the competitive landscape. You need this context to build a strong strategy.</li>
          <li><strong>Stay focused on the user:</strong> Remember, the GTM strategy is about acquiring users and driving engagement. Always think about how to reach and convince them.</li>
          <li><strong>Think long-term:</strong> While the initial launch is important, don't forget to address how you'll maintain user engagement in the long run.</li>
        </ul>
      </section>

    </div>
  );
};

export default withCourseNavigation(GTMStrategyGuide);
