import React from 'react';

const ExecutionInterviewSkills = () => {
  return (
    <div className="">
      <h1 className="course-title">Core Skills to Showcase in Execution Interviews</h1>

      <p className="course-intro">
        Execution interviews are designed to assess your ability to handle the day-to-day responsibilities of a product manager. These questions evaluate key skills like problem-solving, critical thinking, and your cultural fit within the company.
      </p>

      <p className="course-intro">
        Most companies use a scoring system to rank candidates on a five-point scale, ranging from "missing" or "very weak" to "very strong." Your interviewer will assess how well you display the core qualities during the interview process, and this feedback helps shape the hiring decision.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Key Skills to Focus On</h2>
        <p>Here are the core skills you should focus on during execution interviews:</p>

        <div className="skill-section">
          <h3 className="subsection-title">1. Ability to Isolate Variables</h3>
          <p>
            When a product problem arises, it's easy to get overwhelmed by all the possibilities. As a PM, you need to be able to pinpoint cause-and-effect relationships and create a plan to investigate further. Isolating variables is crucial for effective problem-solving in any product or tech role.
          </p>
          <h4>Scoring Scale:</h4>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Did not isolate variables when working through the problem.</li>
            <li><strong>Weak:</strong> Tried isolating variables but made obvious errors.</li>
            <li><strong>Neutral:</strong> Isolated variables reasonably well but needed some nudges.</li>
            <li><strong>Strong:</strong> Effectively isolated variables, considering factors like urgency, cost/benefit, and testability.</li>
            <li><strong>Very Strong:</strong> Isolated variables elegantly; the resulting plan of action was solid and well-thought-out.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">2. Data Literacy</h3>
          <p>
            Just like in analytical interviews, data literacy is essential in execution questions. Interviewers want to see how well you analyze data, identify patterns, suggest meaningful tests, and draw conclusions that make sense.
          </p>
          <h4>Scoring Scale:</h4>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Didn't use data to answer the question.</li>
            <li><strong>Weak:</strong> Missed obvious patterns in the data.</li>
            <li><strong>Neutral:</strong> Reasonably analyzed data but missed a few key points.</li>
            <li><strong>Strong:</strong> Used data effectively, asked insightful questions, made logical assumptions, and drew solid conclusions.</li>
            <li><strong>Very Strong:</strong> Extracted valuable insights, identified key patterns, suggested meaningful reports or tests, and formed logical arguments.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">3. Logical Thinking</h3>
          <p>
            When faced with product challenges, logical thinking is key to making sound decisions. Your interviewer will look for clear, logical steps in how you diagnose and communicate your solutions.
          </p>
          <h4>Scoring Scale:</h4>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Jumped to conclusions without investigating the root cause.</li>
            <li><strong>Weak:</strong> Showed some logical thinking but made significant errors.</li>
            <li><strong>Neutral:</strong> Thought process was mostly logical, but there were a few missteps.</li>
            <li><strong>Strong:</strong> Broke down the problem logically and effectively worked through it.</li>
            <li><strong>Very Strong:</strong> Demonstrated flawless logic with no oversights; communicated thoughts clearly throughout.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">4. Intuition for Problem-Solving</h3>
          <p>
            Good intuition can be a powerful tool when problem-solving, but you should always back up your assumptions with data. Interviewers want to see if you have a strong sense of where problems are likely to come from and how you prioritize them.
          </p>
          <h4>Scoring Scale:</h4>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Showed no intuition for where the problem might be.</li>
            <li><strong>Weak:</strong> Intuition was incorrect or couldn't be justified.</li>
            <li><strong>Neutral:</strong> Reasonably good intuition for where the problem might originate.</li>
            <li><strong>Strong:</strong> Strong sense of likely problem sources and able to confirm intuition with data.</li>
            <li><strong>Very Strong:</strong> Demonstrated deep intuition for how systems work, and the plan reflected this understanding.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">5. Communication</h3>
          <p>
            Communication is assessed in every interview because it's essential for any PM role. How well you explain your thought process and engage with the interviewer will be closely evaluated.
          </p>
          <h4>Scoring Scale:</h4>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Failed to communicate clearly despite prompts.</li>
            <li><strong>Weak:</strong> Poor communication; interviewer had difficulty following along.</li>
            <li><strong>Neutral:</strong> Communication was clear in some areas but vague in others.</li>
            <li><strong>Strong:</strong> Communicated clearly and consistently throughout.</li>
            <li><strong>Very Strong:</strong> Anticipated questions, explained decisions thoroughly, and kept the conversation flowing smoothly.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">6. Collaboration</h3>
          <p>
            Don't forget that execution interviews are often collaborative. You're expected to involve your interviewer in the process, asking them questions or using them as a resource.
          </p>
          <h4>Scoring Scale:</h4>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Did not take the lead or respond to guidance.</li>
            <li><strong>Weak:</strong> Struggled to stay on track without guidance.</li>
            <li><strong>Neutral:</strong> Took the lead but needed a few redirects.</li>
            <li><strong>Strong:</strong> Led the discussion effectively and involved the interviewer throughout.</li>
            <li><strong>Very Strong:</strong> Took the lead and made exceptional use of the interviewer's input, making it feel more like a collaborative discussion than an interview.</li>
          </ul>
        </div>
      </section>

    </div>
  );
};

export default ExecutionInterviewSkills;