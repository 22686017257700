import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const ExecutionIntro = () => {
  return (
    <div className="">
      <h1 className="course-title">Introduction to Execution Questions</h1>

      <p className="course-intro">
        Execution questions are designed to see how you manage the everyday responsibilities of a product manager. They're a chance for interviewers to dig into how you approach real-world problems, analyze data, and communicate solutions.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Common Types of Execution Questions</h2>

        <div className="question-type">
          <h3 className="subsection-title">Root Cause Analysis (RCA) Questions</h3>
          <p>
            Root cause analysis questions usually take the form of role-playing. Your interviewer might act as an analyst who's unclear on the problem, and it's up to you to figure out the root cause and suggest next steps.
          </p>
          <h4 className="example-heading">Example questions:</h4>
          <ul className="bullet-list">
            <li>"You're the PM for a food delivery service like Zomato. Cancellations have increased by 20%. How would you investigate?"</li>
            <li>"You're the PM for an e-commerce site like Flipkart. Sales have dropped by 7% over the last week. What could be wrong?"</li>
          </ul>
        </div>

        <div className="question-type">
          <h3 className="subsection-title">Decision-Making Questions</h3>
          <p>
            These are similar to RCA questions but focus on making a decision based on the scenario you're given. You might be asked for a yes/no answer or an open-ended response.
          </p>
          <h4 className="example-heading">Example questions:</h4>
          <ul className="bullet-list">
            <li>"You're the PM for Hotstar. A new feature improves watch time but decreases engagement (likes and comments). Should you go ahead with it?"</li>
            <li>"You're the PM for Swiggy. Should Swiggy Stores remain a standalone app, or should it be integrated with the main app?"</li>
          </ul>
        </div>

        <div className="question-type">
          <h3 className="subsection-title">Metrics Questions</h3>
          <p>
            Metrics play a huge role in most execution questions. You'll need to think critically about how success is measured and how decisions impact the business.
          </p>
          <h4 className="example-heading">Example questions:</h4>
          <ul className="bullet-list">
            <li>"How would you measure success for Myntra's new feature allowing users to try clothes virtually?"</li>
            <li>"You're working on Paytm's merchant services. What metrics would you track to measure its growth?"</li>
          </ul>
        </div>

        <div className="question-type">
          <h3 className="subsection-title">Craft and Execution</h3>
          <p>
            This is a Google-specific type of question, where you'll be evaluated on your overall product management skills. These are more open-ended and may ask you to reflect on your experiences.
          </p>
          <h4 className="example-heading">Example questions:</h4>
          <ul className="bullet-list">
            <li>"Pick a product like Ola. What are the goals of this product, and what would be in your monthly business review for leadership?"</li>
            <li>"Imagine I'm a VC offering you ₹150 crore to build a tech-enabled product/service. Walk me through how you'd start, from problem to monetization."</li>
          </ul>
        </div>

        <div className="question-type">
          <h3 className="subsection-title">Cross-Functional Collaboration</h3>
          <p>
            Collaboration is key for PMs, especially when working across departments. These questions ask about your experience working with teams like engineering, design, or sales.
          </p>
          <h4 className="example-heading">Example question:</h4>
          <ul className="bullet-list">
            <li>"Your largest customer is asking for a new feature, but it's not on your current roadmap. The sales team is pushing engineering to prioritize it. What would you do?"</li>
          </ul>
        </div>
      </section>

    </div>
  );
};

export default withCourseNavigation(ExecutionIntro);
