import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const CreatingStoryBank = () => {
  return (
    <div className="">
      <h1 className="course-title">Creating a Story Bank</h1>

      <p className="course-intro">
        The best way to prepare for behavioral questions is by building a <strong>story bank</strong>—a collection of personal stories that you can easily refer to during an interview. While you can't predict the exact questions you'll be asked, having a set of prepared stories helps you stay confident and avoid one of the biggest interview traps: <strong>rambling</strong>.
      </p>

      <p className="course-intro">
        Here's how to build your story bank.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Learn the Company Values</h2>
        <p>
          Tech companies often shape their culture around a set of core values. It's crucial to understand these values to ensure you position yourself as a strong cultural fit. For example, here's how you might research a company's values:
        </p>
        <ul className="bullet-list">
          <li><strong>Company website:</strong> Read the value propositions they promote. What's their mission? How do they sell their products to customers?</li>
          <li><strong>Annual reports:</strong> If available, these can offer insight into the company's vision and key objectives.</li>
          <li><strong>Social media:</strong> Observe their tone and branding on platforms like LinkedIn or Twitter. Is it professional? Playful?</li>
          <li><strong>Company review sites:</strong> Check sites like Glassdoor or AmbitionBox to get a sense of the internal culture.</li>
        </ul>
        <p>
          This research will help you tailor your stories to reflect the company's values and position yourself as the ideal candidate.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Choose Your Stories</h2>
        <p>
          Now that you have a sense of the company's values, it's time to choose 5-10 stories from your past experiences that best reflect these values. Here's what to consider when selecting your stories:
        </p>
        <ul className="bullet-list">
          <li><strong>Recent:</strong> Choose experiences from the past two years.</li>
          <li><strong>Detailed:</strong> Include numbers, stakeholder interests, and challenges you faced.</li>
          <li><strong>Diverse:</strong> Pick stories from different work environments, team sizes, and types of problems.</li>
          <li><strong>Engaging:</strong> Pick stories that have some drama or tension—these tend to make a lasting impression.</li>
        </ul>
        <p>
          For example, if the company emphasizes collaboration, select a story that showcases how you navigated different team dynamics to achieve a result.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Add Detail</h2>
        <p>
          Once you've chosen your stories, add in the details. Focus on the <strong>stakeholders</strong> involved in each situation—who they were, what their interests were, and how you managed their expectations. Mapping out the stakeholders will help you answer follow-up questions and show how well you handle cross-functional collaboration.
        </p>
        <p>
          Pick at least one story where things didn't go your way. Reflect on what you learned from that experience and how it changed your approach to problem-solving.
        </p>
        <p>
          Make sure your stories cover:
        </p>
        <ol className="numbered-list">
          <li>The <strong>context</strong>—the situation, the problem, and why it was important.</li>
          <li>The <strong>solution</strong> you came up with.</li>
          <li>The <strong>result</strong>—what impact you had, and how your actions made a difference.</li>
        </ol>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Test Out Your Story Bank</h2>
        <p>
          Once your stories are ready, practice answering potential interview questions with them. If you feel comfortable responding to common behavioral questions using your stories, you're good to go. If not, consider revising your story bank until you feel confident.
        </p>
        <p>
          If you're applying to multiple companies and can't find specific company values, consider using a comprehensive framework like <strong>Amazon's Leadership Principles</strong> to guide your practice, as it covers most behavioral questions.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Rewriting Using the STAR Method</h2>
        <p>
          Once you have your stories detailed, the next step is to refine them using the <strong>STAR framework</strong>:
        </p>
        <ul className="bullet-list">
          <li><strong>Situation:</strong> Describe the context of the story. What was your role? What was the background of the project or challenge?</li>
          <li><strong>Task:</strong> What specific problem were you trying to solve? Consider the interests of various stakeholders.</li>
          <li><strong>Action:</strong> Detail the steps you took to address the problem. Highlight how you communicated, collaborated, and made decisions.</li>
          <li><strong>Result:</strong> What was the outcome? Use numbers or examples to quantify the impact. What did you learn, and how did it shape your approach moving forward?</li>
        </ul>
        <p>
          This format helps ensure your answers are clear, concise, and impactful, keeping your interviewer engaged and avoiding unnecessary detail.
        </p>
      </section>
    </div>
  );
};

export default withCourseNavigation(CreatingStoryBank);
