// src/components/EstimationQuestions/EstimationStrategies.js
import React from 'react';
import { ExternalLink } from 'lucide-react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const InstagramMetricsAnalysis = () => {
  return (
    <div className="">
      <h1 className="course-title">Instagram Metrics</h1>

      <p className="course-intro">
        It's important to remember that there's rarely just one "best" metric. Instead, we often focus on defining "North Star" metrics that reflect the core value of the product. For Instagram, a platform that thrives on social interaction and visual expression, the key is to identify metrics that capture how users engage with the platform and build meaningful connections.
      </p>

      <p className="course-intro">
        To structure our thinking, we can use the GAME framework:
      </p>

      <ul className="bullet-list">
        <li><strong>Goal:</strong> Define Instagram's long-term goals and vision.</li>
        <li><strong>Actions:</strong> Identify the user behaviors that support those goals.</li>
        <li><strong>Metrics:</strong> Select specific metrics to measure those behaviors.</li>
        <li><strong>Evaluate:</strong> Consider the trade-offs and limitations of the metrics you've chosen.</li>
      </ul>

      <section className="interview-section">
        <h2 className="section-title">Goal</h2>
        <p>
          Instagram's core mission is to be a social platform where users connect with others through visual content. It's about creating and sharing moments that allow people to express themselves and bond over shared interests. Instagram isn't just about broadcasting your life; it's about engaging in social experiences that create meaningful connections. The primary goal for Instagram is to increase user engagement, encouraging users to actively interact with posts, stories, and Reels, all while building stronger connections with others on the platform.
        </p>
        <p>
          Instagram has evolved with features like Reels, which are designed to boost both engagement and social sharing. The ability to share Reels and amplify their reach is central to the platform's goal of creating viral, socially-driven content. Sends per reach is a key metric Instagram uses to track how often users share Reels with their network, which strengthens connections and enhances the organic spread of content.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Actions</h2>
        <p>
          When thinking about increasing engagement on Instagram, we should focus on the specific actions that align with its goal of fostering social interaction. These are the behaviors that drive the platform's success and help users build connections:
        </p>
        <ul className="bullet-list">
          <li><strong>Posting stories regularly:</strong> Stories encourage users to share frequent, real-time moments with their followers, creating a steady stream of engagement.</li>
          <li><strong>Liking and commenting on posts:</strong> Likes and comments are straightforward indicators of interaction and engagement, showing that users are participating in conversations around content.</li>
          <li><strong>Engaging with Reels:</strong> Reels allow users to discover, share, and remix creative content, making it a critical feature for social interaction.</li>
          <li><strong>Following new accounts:</strong> Discovering and following new people or brands extends a user's network and creates more opportunities for engagement.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Metrics</h2>
        <p>
          Now that we've identified the actions that support Instagram's goals, the next step is selecting the right metrics to track them. These metrics will help us understand how well Instagram is driving engagement and social connections.
        </p>
        <p>Key metrics to consider include:</p>
        <ul className="bullet-list">
          <li><strong>Sends per reach (Reels):</strong> This is a crucial metric for Instagram. It measures how often users share Reels with others. The more a Reel is shared, the more it extends its reach organically. This metric doesn't just track passive engagement—it highlights social interaction, which is core to Instagram's vision of being a platform for connection.</li>
          <li><strong>Average number of stories posted per user per day:</strong> Stories provide a continuous stream of engagement, and tracking how often users post helps gauge their activity.</li>
          <li><strong>Average Reel watch time per user:</strong> This metric tracks how much time users spend watching Reels. It's important to combine this with engagement metrics like likes, comments, and shares to get a fuller picture of user interaction.</li>
          <li><strong>Average number of likes and comments per user per week:</strong> Likes and comments are simple but powerful indicators of user engagement, showing direct interactions with content.</li>
        </ul>
      </section>

      <div className="bg-red-100 border-l-4 border-orange-500 p-4 my-6">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Instagram CEO Interview on "Sends per reach"</h3>
          <p className="text-gray-600 mb-3">Watch this video of Instagram CEO to learn why 'Sends per Reach' is considered a crucial 
          metric for Instagram. You don't have to watch entire podcast, just 10 mins of conversation should be good enough</p>
          <a 
            href="https://youtu.be/HDzhA_UFrnA?si=N0GVhcefz2u8mnHZ&t=595" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center text-indigo-600 hover:text-indigo-800 transition duration-300"
          >
            <ExternalLink className="w-4 h-4 mr-2" />
            Watch on YouTube
          </a>
       </div>   

      <section className="interview-section">
        <h2 className="section-title">Evaluate</h2>
        <p>
          After selecting these key metrics, we need to consider the potential limitations and trade-offs:
        </p>
        <ul className="bullet-list">
          <li><strong>Sends per reach:</strong> While this is a valuable metric for understanding social engagement, not every share is positive. Some content might be shared because it's controversial or negative. To get a better understanding of the quality of shares, we could use sentiment analysis or gather qualitative feedback to assess whether the shared content is contributing positively to the community.</li>
          <li><strong>Reel watch time:</strong> While watch time is an important measure of engagement, it doesn't always tell the full story. If users are passively consuming content without further interaction (e.g., likes, comments, or shares), then the engagement may not be as meaningful. That's why it's crucial to pair watch time with other metrics that track deeper engagement.</li>
          <li><strong>Likes and comments:</strong> These are solid engagement metrics, but it's important to assess the sentiment behind them. For example, comments could be negative or spam, which might not align with Instagram's goal of fostering a positive, connected community.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Conclusion</h2>
        <p>
          In Instagram's case, key metrics like Sends per reach, Reel watch time, and story posting frequency offer valuable insights into user engagement. Sends per reach stands out as a particularly powerful metric because it captures how social connections drive the spread of content, highlighting Instagram's core value as a platform for sharing and connecting.
        </p>
        <p>
          By focusing on metrics that measure both engagement and social interaction, we can help Instagram continue to grow its community, amplify meaningful connections, and ensure that users find value in engaging with the platform.
        </p>
      </section>

    </div>
  );
};

export default withCourseNavigation(InstagramMetricsAnalysis);