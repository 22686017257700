import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ReactGA from 'react-ga4';
import { AlertCircle, Loader, Mail, Lock } from 'lucide-react';

const Login = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleAuthStateChange = async (event, session) => {
      console.log('Auth state changed:', event, 'Session:', session);
      if (event === 'SIGNED_IN' && session) {
        console.log('User signed in:', session.user);
        setUser(session.user);
        ReactGA.event({
          category: 'User',
          action: 'Login',
          label: session.user
        });
        navigate('/dashboard');
      } else if (event === 'SIGNED_OUT') {
        console.log('User signed out');
        setUser(null);
      }
    };

    const { data: authListener } = supabase.auth.onAuthStateChange(handleAuthStateChange);

    // Check for authentication errors in URL
    const searchParams = new URLSearchParams(location.hash.substring(1));
    const errorDescription = searchParams.get('error_description');
    if (errorDescription) {
      // console.error('Auth error from URL:', errorDescription);
      setError(`Authentication error: ${errorDescription}`);
      if (errorDescription && errorDescription.includes('Database')) {
        setError('Looks like your account has not been created yet. If you have just purchased membership access, please wait another 24 hours for your account to activate.');
      } else if (errorDescription && errorDescription.includes('Signups')){
        setError('Looks like your account has not been created yet. If you have just purchased membership access, please wait another 24 hours for your account to activate.');
      }
      else {
        setError('Oops, something went wrong. Please try again later.');
      }
    }

    // Check if we're returning from a Google login
    if (location.hash.includes('access_token')) {
      // console.log('Detected access token in URL, attempting to retrieve session');
      supabase.auth.getSession().then(({ data: { session } }) => {
        if (session) {
          console.log('Session retrieved after Google login:', session);
          ReactGA.event({
            category: 'User',
            action: 'Login',
            label: session.user
          });
          setUser(session.user);
          navigate('/dashboard');
        } else {
          console.error('No session found after Google login');
          setError('Failed to retrieve user session after Google login.');
        }
      });
    }

    return () => {
      console.log('Cleaning up auth listener');
      authListener.subscription.unsubscribe();
    };
  }, [navigate, setUser, location]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      console.log('Attempting email/password login');
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      console.log('Email/password login successful:', data.user);
      setUser(data.user);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'Invalid login credentials. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // new login method
  const handleGoogleLogin = async () => {
  setError('');
  setIsLoading(true);
  try {
    console.log('Initiating Google login');
    
    // const redirectTo =
    //   process.env.NODE_ENV === 'development'
    //     ? 'http://localhost:3000/login' // Localhost URL
    //     : 'https://app.theproducthope.com/login'; // Production URL
        
    // Initiate Google login
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/login`, // Redirect to the current page after login
      },
    });

    // Do not throw an error if the redirection was successful
    if (data?.url) {
      console.log('Redirecting to Google login:', data.url);
      return; // Stop execution here because the user is being redirected
    }

    // Handle errors that might occur (unlikely in this flow)
    if (error) {
      console.error('Error initiating Google login:', error);
      setError('Error initiating Google login. Please try again.');
    }

    console.log('Google login initiated:', data);

    // Wait for the session to be set after successful sign-in
    const { data: session, error: sessionError } = await supabase.auth.getSession();
    if (sessionError) throw sessionError;

    if (session) {
      const userEmail = session.user.email;
      console.log('Checking if user exists in database:', userEmail);

      // Query the database for the user's email
      const { data: users, error: dbError } = await supabase
        .from('users') // Replace 'users' with your actual table name
        .select('email')
        .eq('email', userEmail);

      if (dbError) {
        console.error('Error querying database:', dbError);
        setError('An error occurred while checking your account. Please try again.');
        return;
      }

      if (users.length === 0) {
        console.warn('User not found in database:', userEmail);

        // Log out the user since the account is not created yet
        await supabase.auth.signOut();
        setError('Account not created yet. Please contact support or sign up first.');
        return;
      }

      console.log('User exists in database:', userEmail);
      setUser(session.user);
      navigate('/dashboard');
    }
  } catch (error) {
    console.error('Google login error:', error);
    setError('Error initiating Google login. Please try again.');
  } finally {
    setIsLoading(false);
  }

};



  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md">
        <div className="bg-white shadow-md rounded-lg px-8 py-6">
          <h2 className="text-2xl font-bold text-center mb-6">Login to ProductHope</h2>
          {error && (
            <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <div className="flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                <span>{error}</span>
              </div>
            </div>
          )}
          <div className="mt-6">
              <button
                onClick={handleGoogleLogin}
                disabled={isLoading}
                className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <img className="h-5 w-5 mr-2" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" />
                Sign in with Google
              </button>
          </div>
          {/* <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>
          </div> 
          <form onSubmit={handleLogin} className="space-y-6 mt-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email address
              </label>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your email address"
                />
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your password"
                />
                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <Loader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Logging in...
                </span>
              ) : (
                'Log in'
              )}
            </button>
          </form> */}
          
          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">
              Don't have an account?{' '}
              <Link to="https://theproducthope.com" className="font-medium text-blue-600 hover:text-blue-500">
                Get Community Access
              </Link>
            </p>
            <p className="mt-2 text-sm text-gray-600">
              If you have recently bought the membership, please wait for 24 hrs to activate your account.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;