import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const EstimationQuestionsIntro = () => {
  return (
    <div className="">
      <h1 className="course-title">Introduction to Estimation Questions</h1>

      <section className="intro-section">
        <p>
          As a Product Manager, you'll often face situations where decisions need to be made based on incomplete or rough data. Whether it's estimating the potential market size for a new feature or figuring out how many users might adopt a new service, estimation questions test your ability to break down complex, ambiguous problems.
        </p>
        <p>
          Estimation questions such as "How many rental properties are listed on Airbnb in Mumbai?" may seem daunting at first glance. But don't worry—the goal isn't to arrive at an exact answer. What interviewers really want to see is how you approach solving the problem logically.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What to Expect in Estimation Questions</h2>
        <p>
          You'll likely be asked to estimate things that seem difficult to measure at first, such as:
        </p>
        <ul className="bullet-list">
          <li>"How much revenue does Flipkart generate during its annual Big Billion Days sale?"</li>
          <li>"Estimate the number of smartphones sold in India this year."</li>
          <li>"What's the market size for electric scooters in India by 2030?"</li>
        </ul>
        <p>
          While the specific information needed will vary, the process for answering most estimation questions remains the same. Estimation questions generally assess your ability to:
        </p>
        <ul className="bullet-list">
          <li><strong>Understand the strategic context:</strong> <em>"What's the total addressable market (TAM) for Swiggy in tier-2 cities?"</em></li>
          <li><strong>Utilize technical knowledge:</strong> <em>"How much does it cost to run Amazon's data centers in India for a day?"</em></li>
          <li><strong>Plan execution:</strong> <em>"What would it cost to launch drone delivery services in Bangalore?"</em></li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Framework for Approaching Estimation Questions</h2>
        <p>
          In this section, we'll provide a step-by-step framework for tackling estimation questions. The key is to break down the problem into smaller, more manageable pieces. You'll need to reframe the problem, identify critical variables, and make educated guesses to fill in any unknowns. We'll walk you through how to estimate using different methods, and how to evaluate whether your answer is reasonable in the given context.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What Interviewers Are Looking For</h2>
        <p>
          When answering estimation questions, interviewers are evaluating whether you can:
        </p>
        <ol className="numbered-list">
          <li><strong>Make sense of the problem:</strong> Can you break down the big question into logical parts and understand what you're really being asked?</li>
          <li><strong>Divide and conquer:</strong> Are you able to deconstruct the problem into smaller, easier-to-estimate pieces?</li>
          <li><strong>Make smart assumptions:</strong> Are your assumptions grounded in reality? Can you justify them?</li>
          <li><strong>Manage complexities:</strong> Can you identify and account for important factors, while ignoring less significant ones?</li>
          <li><strong>Handle numbers confidently:</strong> Are you able to manipulate numbers and perform quick mental math without getting stuck?</li>
          <li><strong>Evaluate your results:</strong> Does your final answer make sense in the context of the problem?</li>
        </ol>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Examples of "Okay," "Good," and "Great" Answers</h2>
        <div className="answer-quality">
          <h3>An okay answer</h3>
          <p>
            Shows that the candidate can break the problem down into logical steps but may rely on rough or overly broad assumptions. They may get close to the answer but struggle to explain why certain assumptions were made.
          </p>
        </div>
        <div className="answer-quality">
          <h3>A good answer</h3>
          <p>
            Breaks the problem down clearly, using well-reasoned assumptions and justifying their estimates. The candidate can explain their approach and tweak assumptions to show how changes would affect the outcome.
          </p>
        </div>
        <div className="answer-quality">
          <h3>A great answer</h3>
          <p>
            Not only provides a structured breakdown and thoughtful assumptions but also considers the broader context. The candidate incorporates a deep understanding of the industry or market and can explain how their final answer aligns with real-world data. They can also adjust their assumptions on the fly and articulate how different scenarios would impact the estimate.
          </p>
        </div>
      </section>
    </div>
  );
};

export default withCourseNavigation(EstimationQuestionsIntro);
