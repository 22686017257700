import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const TechnicalProductQuestionsIntro = () => {
  return (
    <div className="">
      <h1 className="course-title">Introduction to Technical Product Questions</h1>

      <p className="course-intro">
        Technical questions might not always pop up in product manager interviews unless you're applying for a specifically technical role (for example, Technical Product Manager at companies like Amazon). In fact, companies like Google have recently stopped asking technical questions for most PM roles.
      </p>

      <p className="course-intro">
        However, having some technical knowledge can be a game-changer. It helps you evaluate if a product idea is realistic and understand how new technologies can impact your strategy.
      </p>

      <section className="interview-section">
        <h2 className="section-title">What to Expect</h2>
        <p>
          Your recruiter should tell you if a technical round is coming up. If you're unsure, it's always okay to ask. When technical questions do come up, here are three types you should prepare for:
        </p>
        <ul className="bullet-list">
          <li><strong>System Design:</strong> "How would you design the architecture for an app like ShareChat's Home Feed?"</li>
          <li><strong>Explaining Technical Concepts:</strong> "Can you explain what happens when you type a URL, like 'flipkart.com', into your browser?"</li>
          <li><strong>Justifying Technical Decisions:</strong> "Tell me about a time when your team had to choose between two tech solutions with trade-offs. What did you pick, and why?"</li>
        </ul>
        <p>
          In this module, we'll walk through how to tackle these kinds of technical questions, using both your product management skills and any technical know-how you have.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What Interviewers Are Looking For</h2>
        <p>
          Interviewers ask technical questions to understand your:
        </p>
        <ul className="bullet-list">
          <li><strong>Technical understanding</strong> and how comfortable you are working with data and systems.</li>
          <li><strong>Ability to communicate technical concepts</strong> to non-technical stakeholders (like users).</li>
          <li><strong>Familiarity with how products are built</strong> from a development perspective.</li>
          <li><strong>Awareness of trade-offs</strong> in tech choices and how those decisions affect users and the business.</li>
        </ul>
        <p className="pro-tip">
          Pro tip: You won't have to deal with coding or algorithms here—so no need to worry about writing code in these interviews!
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">"Okay" vs. "Good" vs. "Great" Answers</h2>
        <ul className="bullet-list">
          <li>
            An <strong>okay answer</strong> gives a basic explanation of the technology or system. You show that you can follow technical discussions, which is enough for working with engineers.
          </li>
          <li>
            A <strong>good answer</strong> dives deeper into the complexities, maybe touching on trade-offs or challenges. You demonstrate that you can anticipate what engineers might care about and address those points proactively.
          </li>
          <li>
            A <strong>great answer</strong> showcases a solid grasp of the technology's finer details. You're able to predict risks, make well-informed suggestions, and engage with engineers on a deeper level, speaking their language fluently.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default withCourseNavigation(TechnicalProductQuestionsIntro);
