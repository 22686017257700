import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const ProductDesignIntroUpdated = () => {
  return (
    <div className="">
      <h1 className="course-title">Introduction to Product Design Questions</h1>

      <p className="course-intro">
        In product management interviews, you'll often be asked to design, improve, or discuss a product. Different companies might call this "Product Sense" or "Design Thinking," but the idea is the same—this is your chance to prove that you can create products people actually want to use.
      </p>
      <p className="course-intro">
        These questions are your time to shine! You'll get to show how you think through user needs, solve real problems, and align the product with both the company's goals and the broader market.
      </p>

      <section className="interview-section">
        <h2 className="section-title">What to Expect</h2>
        <p>Here are the most common types of product design questions you'll face:</p>
        <ul className="bullet-list">
          <li><strong>Design a product:</strong> <em>"Imagine designing something for people stuck in Mumbai traffic for hours."</em></li>
          <li><strong>Improve an existing product:</strong> <em>"How would you make the Swiggy app better for families placing large orders?"</em></li>
          <li><strong>Your favorite product:</strong> <em>"What's your favorite app or service, and why?"</em></li>
          <li><strong>Brainstorm new ideas:</strong> <em>"Let's say Google's adding a voice-activated assistant for local languages. What features could it offer to improve accessibility in rural India?"</em></li>
        </ul>
        <p>
          In this module, we'll walk you through a framework to tackle these questions. We'll help you identify the right users, pinpoint their biggest problems, and craft smart solutions. You'll also learn how to present your ideas clearly while keeping the company's larger vision in mind.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What Interviewers Want to See</h2>
        <p>Interviewers aren't just asking product design questions for fun—they're looking to evaluate your key skills as a product manager. Specifically, they want to see:</p>
        <ul className="bullet-list">
          <li>How well you understand and empathize with users</li>
          <li>Your ability to design intuitive products</li>
          <li>How strategic and business-savvy your ideas are</li>
          <li>How you prioritize between competing needs</li>
          <li>Your passion for creative problem-solving</li>
        </ul>
        <p>
          To stand out, you'll need to show that you can solve a real user problem in a way that's practical, valuable, and future-proof.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What Makes an Answer "Okay," "Good," or "Great"?</h2>
        <p>Here's a breakdown of how your product design answers can range from just okay to truly outstanding:</p>
        <div className="example-box">
          <h3>Okay answer:</h3>
          <p>
            You identify a user segment and their key pain points. You suggest a basic solution that's reasonable but doesn't go too deep.
          </p>
        </div>
        <div className="example-box">
          <h3>Good answer:</h3>
          <p>
            You clearly explain who the users are and what they care about. You prioritize their most critical problems and propose a few solid solutions that fit their needs. You're on the right track but might not fully connect the dots between users, their pain points, and the bigger picture.
          </p>
        </div>
        <div className="example-box">
          <h3>Great answer:</h3>
          <p>
            You dive deep into understanding your users—their goals, their context, and why they're struggling. You offer bold but realistic solutions that not only address immediate problems but also align with the company's long-term strategy. Plus, you place your ideas within the broader market, considering competition and trends.
          </p>
        </div>
      </section>
    </div>
  );
};

export default withCourseNavigation(ProductDesignIntroUpdated);