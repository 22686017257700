import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const StrategyQuestionsIntro = () => {
  return (
    <div className="">
      <h1 className="course-title">Introduction to Strategy Questions</h1>

      <p className="course-intro">
        As a product manager, you'll be involved in big strategic decisions—how to price products, whether to enter new markets, or how to tackle competition. These decisions shape the future of the product and company, so it's important to have a solid grasp of strategy.
      </p>

      <p className="course-intro">
        Strategy questions in PM interviews challenge you to analyze the market, understand the competition, assess the company's strengths and weaknesses, and then craft a strategy that supports the company's long-term vision. These questions are especially common in senior-level PM interviews, but even if you're at a junior or mid-level, you can expect at least one strategy question to come your way.
      </p>

      <section className="interview-section">
        <h2 className="section-title">What to Expect</h2>
        <p>Product strategy questions can be broad and open-ended, allowing you to showcase your problem-solving abilities. Here are a few common types:</p>
        <ul className="bullet-list">
          <li><strong>Strategic Analysis:</strong> "What's the biggest threat to Instagram?"</li>
          <li><strong>Go-to-Market:</strong> "As the PM launching Youtube's community membership product feature, how would you acquire users?"</li>
          <li><strong>Pricing:</strong> "You're a PM at a Swiggy. How would you price Swiggy One membership"</li>
          <li><strong>Growth:</strong> "Imagine you're a PM at Blinkit. How would you double its market share in the next three years?"</li>
          <li><strong>Monetization:</strong> "How would you monetize WhatsApp?"</li>
          <li><strong>Market Entry:</strong> "Should Amazon enter into ChatGPT like tool?"</li>
          <li><strong>New Technology:</strong> "Meta has developed a new technology. How would you monetize it?"</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What Interviewers are Looking For</h2>
        <p>Strategy questions allow interviewers to evaluate how you:</p>
        <ul className="bullet-list">
          <li>Understand the company, product, and industry landscape to make informed decisions</li>
          <li>Align with company and product goals when making strategic choices</li>
          <li>Build multiple viable options to consider</li>
          <li>Make sound decisions with limited information</li>
          <li>Keep long-term success in focus</li>
        </ul>
        <p>Depending on the specific question, you'll also need to demonstrate particular skills like understanding pricing models or reasoning about growth levers.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">"Okay" vs. "Good" vs. "Great" Answers</h2>
        <div className="example-box">
          <h3>Okay Answer:</h3>
          <p>You give a reasonable summary of the product, company, and industry, and provide a viable direction for the company to take.</p>
        </div>
        <div className="example-box">
          <h3>Good Answer:</h3>
          <p>You dig deeper into what's most critical for the company, incorporating it into a decision that actively drives toward key goals.</p>
        </div>
        <div className="example-box">
          <h3>Great Answer:</h3>
          <p>You offer sharp insights into the product, company, and industry landscape, using these insights to build a strategy that not only solves the current problem but also opens up future possibilities. A great answer might include discussion around risk mitigation, potential next steps with more information, or how the long-term vision could evolve.</p>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">A General Framework for Strategy Questions</h2>
        <ol className="numbered-list">
          <li><strong>Clarify the Problem:</strong> Make sure you understand the question by asking clarifying questions about the product, market, or constraints.</li>
          <li><strong>Define Goals:</strong> What does success look like? Make sure you know what the company is aiming for—whether it's growth, profitability, market expansion, etc.</li>
          <li><strong>Establish Guiding Principles:</strong> Consider the company's mission, values, and strategic priorities. This will guide your decision-making.</li>
          <li><strong>Build a Strategy:</strong> Based on your understanding, create a set of strategic options and then choose the most viable one. Justify your choice based on business impact, customer needs, and competitive advantage.</li>
          <li><strong>Recap and Evolve:</strong> Summarize your strategy and discuss how it could evolve over time. Don't forget to talk about risks and trade-offs, and how you'd mitigate them.</li>
        </ol>
        <p>This framework will help you approach any product strategy question, allowing you to demonstrate strong product thinking and long-term vision. As we move forward, we'll cover common variants of strategy questions in detail, like go-to-market, pricing, and growth.</p>
      </section>

    </div>
  );
};

export default withCourseNavigation(StrategyQuestionsIntro);
