import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const RootCauseAnalysisGuide = () => {
  return (
    <div className="">
      <h1 className="course-title">How to Answer Root Cause Analysis Questions</h1>

      <p className="course-intro">
        Root cause analysis (RCA) questions are one of the most common types of execution questions in product management interviews. They present you with a situation where something unexpected happens with a product, and as a PM, your job is to figure out what went wrong.
      </p>

      <div className="interview-section">
        <h2 className="section-title">Example Question</h2>
        <p>For example, you might be asked something like:</p>
        <blockquote className="example-quote">
          "Imagine you're a PM at Zomato, and you see a 20% increase in order cancellations. What would you do?"
        </blockquote>
      </div>

      <section className="interview-section">
        <h2 className="section-title">What Interviewers Are Looking For</h2>
        <p>
          While your goal is to find the root cause, interviewers are actually more interested in seeing how you approach the problem. They care about your thought process, not just the final answer. So, focus on:
        </p>
        <ul className="bullet-list">
          <li>Making sense of the problem with limited data</li>
          <li>Generating reasonable hypotheses</li>
          <li>Asking for relevant information iteratively</li>
          <li>Identifying the root cause</li>
        </ul>
        <p>
          Even if you don't land on the exact answer, a thoughtful and logical approach can still leave a positive impression.
        </p>
      </section>
       
      <section className="interview-section">
        <h2 className="section-title">A Framework for Answering RCA Questions</h2>
        <p>
          RCA questions typically need to be solved within 25 minutes, so having a clear framework is essential. Here's a simple six-step process to keep you on track:
        </p>
        <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/w4r1xpH.png" 
            alt="Skills for product managers" 
            className="w-full h-auto rounded-lg"
          />
        </div>
        <ol className="numbered-list">
          <li>Clarify and gather context</li>
          <li>Form high-level hypotheses</li>
          <li>Gather data</li>
          <li>Refine hypotheses and repeat</li>
          <li>Identify the root cause</li>
          <li>Evaluate</li>
        </ol>
        <p>Let's break down these steps using an example.</p>
      </section>

    <section className="interview-section">
      <h2 className="section-title">RCA Example: Amazon Late Deliveries</h2>
      
      <div className="step-explanation">
        <h3 className="subsection-title">Scenario Context</h3>
        <p>Imagine you are a PM at Amazon, and there's been a 15% increase in late deliveries. How would you approach this problem?</p>
      </div>

      <div className="step-explanation">
        <h3 className="subsection-title">Step 1: Clarify and Gather Context</h3>
        <p>Start by asking clarifying questions to understand the scope of the issue and the factors involved:</p>
        <ul className="bullet-list">
          <li>"What constitutes a 'late delivery'? Is this based on promised delivery dates or user-reported dissatisfaction?"</li>
          <li>"Does this trend apply to all categories or specific types of products?"</li>
          <li>"Is this a global trend, or is it concentrated in particular regions or markets?"</li>
          <li>"How does this compare to our historical late delivery rates? Is this a steady increase or a sudden spike?"</li>
        </ul>
        <p><strong>Interviewer's Details:</strong></p>
        <ul className="bullet-list">
          <li>A late delivery is any order that arrives after the promised delivery date.</li>
          <li>The increase is global, across all product categories.</li>
          <li>The trend started two months ago and has been consistently rising.</li>
        </ul>
      </div>

      <div className="step-explanation">
        <h3 className="subsection-title">Step 2: Form High-Level Hypotheses</h3>
        <p>Form high-level hypotheses about what might be causing the issue:</p>
        
        <h4>Logistics/Operational Issues:</h4>
        <ul className="bullet-list">
          <li>Delays in Amazon's fulfillment centers or warehouse operations.</li>
          <li>Problems with third-party carriers such as FedEx or UPS causing bottlenecks.</li>
        </ul>

        <h4>External Factors:</h4>
        <ul className="bullet-list">
          <li>Weather disruptions, strikes, or other external events affecting delivery times.</li>
          <li>Regulatory changes (e.g., import/export restrictions or fuel price increases).</li>
        </ul>

        <h4>Product or Technical Bugs:</h4>
        <ul className="bullet-list">
          <li>A technical issue in the supply chain system causing delays in order processing or routing.</li>
          <li>Bugs in the estimated delivery date algorithm, providing unrealistic delivery times to customers.</li>
        </ul>

        <h4>Demand Spikes:</h4>
        <ul className="bullet-list">
          <li>A surge in orders (e.g., during holiday sales) overwhelming the system.</li>
          <li>Unexpected demand in specific regions or categories leading to inventory shortages.</li>
        </ul>
      </div>

      <div className="step-explanation">
        <h3 className="subsection-title">Step 3: Gather Data</h3>
        <p>Gather specific data points to isolate key variables:</p>

        <h4>Operational Metrics:</h4>
        <ul className="bullet-list">
          <li>"Have there been any bottlenecks or capacity issues reported in Amazon's fulfillment centers?"</li>
          <li>"Are third-party carriers reporting higher-than-usual delays?"</li>
        </ul>

        <h4>Geographic or Category Trends:</h4>
        <ul className="bullet-list">
          <li>"Are late deliveries more concentrated in specific regions or affecting certain product categories?"</li>
        </ul>

        <h4>System and Product Data:</h4>
        <ul className="bullet-list">
          <li>"Were there any system updates or changes to the delivery estimation algorithm recently?"</li>
          <li>"Have there been discrepancies between the promised delivery times and actual delivery capabilities?"</li>
        </ul>

        <h4>External Trends:</h4>
        <ul className="bullet-list">
          <li>"Have there been recent external factors, like weather events or fuel price hikes, that could affect delivery performance?"</li>
        </ul>

        <p><strong>Interviewer's Information:</strong></p>
        <ul className="bullet-list">
          <li>The issue is worse in urban areas, where delivery is typically faster.</li>
          <li>Third-party carriers report no operational issues.</li>
          <li>There was a system update to the delivery estimation algorithm two months ago, shortly before the increase in late deliveries.</li>
        </ul>
      </div>

      <div className="step-explanation">
        <h3 className="subsection-title">Step 4: Refine Hypotheses and Repeat</h3>
        <h4>Deprioritized Hypotheses:</h4>
        <ul className="bullet-list">
          <li>External factors can likely be ruled out since there's no evidence of weather disruptions or strikes.</li>
          <li>Third-party carrier delays also don't seem to explain the issue.</li>
        </ul>

        <h4>Refined Hypothesis:</h4>
        <p>"The problem seems related to the recent update to the delivery estimation algorithm. If the algorithm is over-promising delivery dates, it could be creating unrealistic customer expectations."</p>

        <p>Follow-up questions:</p>
        <ul className="bullet-list">
          <li>"What specific changes were made to the delivery estimation algorithm?"</li>
          <li>"Have customer complaints about delivery times increased in parallel with this trend?"</li>
        </ul>

        <p><strong>Interviewer's Confirmation:</strong></p>
        <ul className="bullet-list">
          <li>The algorithm update aimed to provide more competitive delivery times compared to other e-commerce platforms.</li>
          <li>Customer complaints about late deliveries have also risen by 20%.</li>
        </ul>
      </div>

      <div className="step-explanation">
        <h3 className="subsection-title">Step 5: Identify the Root Cause</h3>
        <blockquote className="example-quote">
          "The data suggests that the issue stems from the recent delivery estimation algorithm update. By setting overly aggressive delivery promises to remain competitive, we've unintentionally caused a 15% increase in late deliveries, as the logistics system can't meet these expectations."
        </blockquote>
      </div>

      <div className="step-explanation">
        <h3 className="subsection-title">Step 6: Evaluate</h3>
        
        <h4>Immediate Mitigation:</h4>
        <p>"In the short term, we should roll back or adjust the delivery estimation algorithm to provide more realistic delivery times. While this may make us less competitive in the short term, it will improve customer satisfaction and reduce complaints."</p>

        <h4>Long-Term Solutions:</h4>
        <ul className="bullet-list">
          <li>"We should invest in logistics capacity to ensure we can reliably meet faster delivery promises. This includes optimizing warehouse operations and exploring partnerships with additional carriers."</li>
          <li>"Introduce dynamic delivery promises based on real-time logistics data, so customers get accurate delivery windows tailored to their location and order specifics."</li>
        </ul>

        <h4>Metrics to Track:</h4>
        <ul className="bullet-list">
          <li>Percentage of on-time deliveries.</li>
          <li>Customer satisfaction (e.g., fewer complaints about late deliveries).</li>
          <li>Operational capacity utilization to ensure fulfillment centers and carriers can handle demand.</li>
        </ul>

        <h4>Risks and Trade-offs:</h4>
        <ul className="bullet-list">
          <li>"Adjusting the algorithm may temporarily reduce order volume if customers perceive slower delivery times as a competitive disadvantage."</li>
          <li>"However, failing to address the issue risks long-term brand damage and customer churn."</li>
        </ul>
      </div>
    </section>

      <section className="interview-section">
        <h2 className="section-title">Common Pitfalls to Avoid</h2>
        <ul className="bullet-list">
          <li>
            <strong>Jumping straight to conclusions:</strong> It's tempting to guess the root cause right away, but this can waste time. Take a step back and work through the process systematically.
          </li>
          <li>
            <strong>Not explaining your thought process:</strong> Make sure to articulate why you're asking certain questions and how they help refine your hypotheses. Otherwise, the interviewer might feel like you're randomly guessing.
          </li>
        </ul>
      </section>

    </div>
  );
};

export default withCourseNavigation(RootCauseAnalysisGuide);
