import React from 'react';

const ProductDesignInterviewRubric = () => {
  return (
    <div className="">
      <h1 className="course-title">Evaluation for Product Design Interviews</h1>

      <p className="course-intro">
        Now that you know how to approach product design questions, let's break down how you'll be evaluated during the interview. These questions aren't just about coming up with a good product idea—they test your overall product management skills, critical thinking, and whether you're a good fit for the company's culture.
      </p>

      <p className="course-intro">
        Companies usually score candidates on a five-point scale, ranging from "missing" or "very weak" to "very strong" across key categories. Your goal is to consistently demonstrate the core skills interviewers are looking for in a product manager.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Core Skills to Show in Product Design Interviews</h2>
        <ul className="bullet-list">
          <li><strong>Product Skills:</strong> Can you think strategically about the business? Do you have a deep focus on the user? Can you articulate a clear product vision?</li>
          <li><strong>Critical Thinking:</strong> Are you asking the right clarifying questions? Can you identify trade-offs and potential risks?</li>
          <li><strong>Culture Fit:</strong> Are you passionate about the product and company? How do you communicate and collaborate?</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Evaluation Criteria</h2>

        <div className="skill-section">
          <h3 className="subsection-title">Business Acumen</h3>
          <p>A major part of being a PM is understanding how your product fits into the larger business. Interviewers want to see that your product ideas align with the company's goals and industry trends.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Didn't consider business goals or context.</li>
            <li><strong>Weak:</strong> Struggled to tie the design back to business objectives.</li>
            <li><strong>Neutral:</strong> Mentioned business goals, but the approach was unclear or flawed.</li>
            <li><strong>Strong:</strong> Clearly linked the product idea to business goals, trends, and positioning.</li>
            <li><strong>Very Strong:</strong> Demonstrated deep understanding of the business landscape, with insightful assumptions and logical arguments.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">User-Centricity</h3>
          <p>Great PMs always put users at the center of their product decisions. Interviewers want to see you prioritizing the user's needs while balancing the company's objectives.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Didn't consider the end-user at all.</li>
            <li><strong>Weak:</strong> Had trouble focusing on the user's needs, even with guidance.</li>
            <li><strong>Neutral:</strong> Tried to design for the user, but missed key points.</li>
            <li><strong>Strong:</strong> Identified user pain points and prioritized effectively.</li>
            <li><strong>Very Strong:</strong> Analyzed users deeply, tied every part of the design back to their needs, and prioritized features based on user impact.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">Product Vision</h3>
          <p>It's not enough to just design for the present—PMs need to think ahead. Can you explain where the product will be in 1, 5, or even 10 years? Interviewers look for candidates who can outline a thoughtful vision for the future.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Didn't discuss the future at all.</li>
            <li><strong>Weak:</strong> Struggled to articulate a clear product vision.</li>
            <li><strong>Neutral:</strong> Laid out a potential future but with some errors.</li>
            <li><strong>Strong:</strong> Articulated a clear and thoughtful product vision.</li>
            <li><strong>Very Strong:</strong> Showed strong product intuition with compelling arguments backed by data, tied to user experience and long-term goals.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">Clarifying Questions</h3>
          <p>Product design is about solving problems, and to get to the root of any problem, you need to ask good questions. Interviewers expect you to clarify any uncertainties before jumping into the design.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Didn't ask any clarifying questions.</li>
            <li><strong>Weak:</strong> Asked a few questions, but missed critical ones.</li>
            <li><strong>Neutral:</strong> Asked decent clarifying questions but missed some key points.</li>
            <li><strong>Strong:</strong> Asked insightful questions that helped shape the design.</li>
            <li><strong>Very Strong:</strong> Asked smart, surprising questions that led to novel design insights.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">Ability to Discuss Trade-offs and Possible Errors</h3>
          <p>Product decisions always involve trade-offs. The interviewer wants to see if you can weigh different options logically and identify any risks or assumptions in your approach.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Ignored trade-offs or errors.</li>
            <li><strong>Weak:</strong> Mentioned trade-offs but didn't justify decisions well.</li>
            <li><strong>Neutral:</strong> Discussed trade-offs and errors but could have made better choices.</li>
            <li><strong>Strong:</strong> Logically discussed trade-offs and identified potential errors.</li>
            <li><strong>Very Strong:</strong> Showed deep understanding of trade-offs, offered alternatives, and summarized pros and cons clearly.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">Passion and Creativity</h3>
          <p>Product design interviews are your chance to show enthusiasm for the product and the company. Interviewers want to see that you're excited about solving problems and can think creatively about the product.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Showed no enthusiasm or creativity.</li>
            <li><strong>Weak:</strong> Ideas were bland, and you didn't show interest in the problem.</li>
            <li><strong>Neutral:</strong> Displayed reasonable interest and creativity, but nothing exceptional.</li>
            <li><strong>Strong:</strong> Demonstrated strong enthusiasm, knowledge, and creativity.</li>
            <li><strong>Very Strong:</strong> Gave inspired answers with clear passion for the product.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">Communication</h3>
          <p>Clear communication is essential. Your interviewer needs to understand your thought process and follow your logic without getting lost.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Failed to communicate clearly, even with prompts.</li>
            <li><strong>Weak:</strong> Poor communication; interviewer struggled to follow along.</li>
            <li><strong>Neutral:</strong> Communication was clear in some areas, but vague or incomplete in others.</li>
            <li><strong>Strong:</strong> Articulated thoughts clearly and consistently.</li>
            <li><strong>Very Strong:</strong> Proactive and clear communication; anticipated questions and checked in regularly during the discussion.</li>
          </ul>
        </div>

        <div className="skill-section">
          <h3 className="subsection-title">Collaboration</h3>
          <p>Design interviews are also a chance to collaborate with the interviewer. Think of it as a team exercise—ask for their input, check your assumptions, and work together to come up with the best solution.</p>
          <ul className="bullet-list">
            <li><strong>Very Weak or Missing:</strong> Didn't collaborate or respond to guidance.</li>
            <li><strong>Weak:</strong> Struggled to stay on track without the interviewer's help.</li>
            <li><strong>Neutral:</strong> Led the discussion but occasionally needed guidance or hints.</li>
            <li><strong>Strong:</strong> Effectively led the discussion and involved the interviewer throughout.</li>
            <li><strong>Very Strong:</strong> Created a collaborative, engaging conversation; turned the interview into more of a team problem-solving session.</li>
          </ul>
        </div>
      </section>

      <div className="navigation-buttons">
        <button className="nav-button prev-button">Previous</button>
        <button className="nav-button next-button">Next</button>
      </div>
    </div>
  );
};

export default ProductDesignInterviewRubric;