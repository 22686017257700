import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const BehavioralInterviewsPM = () => {
  return (
    <div className="">
      <h1 className="course-title">Behavioral Interviews for Product Managers</h1>

      <p className="course-intro">
        As a product manager, your ability to work effectively with others plays a huge role in your success. Product management is a highly cross-functional job, so interviewers will assess not just your product design, strategy, and analytical skills, but also your behavior, attitude, and how you think through situations. That's why behavioral interviews are a common part of the process.
      </p>

      <section className="interview-section">
        <h2 className="section-title">What Do Behavioral Interviews Assess?</h2>
        <p>Companies want to hire candidates who:</p>
        <ul className="bullet-list">
          <li>Can handle the responsibilities of the role.</li>
          <li>Are willing to learn and grow while working in a team.</li>
          <li>Align with the company's culture and values.</li>
        </ul>
        <p>
          While technical or domain-specific questions test your skills, behavioral questions aim to gauge if you're a good cultural fit. This might not seem important if you're confident in your skills, but remember: product management requires collaboration, communication, and the ability to live the company's values. Hiring the right person is a big investment for companies, so they want to ensure you're the best fit.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What Kind of Behavioral Questions Should You Prepare For?</h2>
        <p>
          There are two main types of questions in behavioral interviews: experiential and hypothetical.
        </p>
        
        <h3 className="subsection-title">Experiential questions</h3>
        <p>
          Experiential questions ask about your past experiences, helping the interviewer understand how you've handled real-world challenges at work. Here are some examples:
        </p>
        <ul className="bullet-list">
          <li>"Tell me about yourself."</li>
          <li>"Describe a time when you made a mistake at work."</li>
          <li>"Give me an example of a time when you took on a leadership role."</li>
        </ul>

        <h3 className="subsection-title">Hypothetical questions</h3>
        <p>
          Hypothetical questions are based on "what if" scenarios. These questions test your intuition, values, and how well you apply lessons from past experiences to new situations. The key to answering these questions is to connect them to concrete experiences you've had. For example:
        </p>
        <ul className="bullet-list">
          <li>"How would you respond if a colleague disagreed with you?"</li>
          <li>"What would you do if a critical deadline was suddenly moved up?"</li>
          <li>"How would you handle managing multiple tasks at once?"</li>
        </ul>
        <p>
          Companies use these questions to see if you can think on your feet and how well your decision-making aligns with their culture and values.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Why Is This Important?</h2>
        <p>
          Behavioral interviews help companies assess if you'll fit into their work culture. The questions allow them to see how your past experiences align with their values and how you handle workplace situations. A common question you'll likely encounter is, "Why do you want to work here?" Your answer to this question often indicates if your values match the company's.
        </p>
        <div className="pro-tip">
          <strong>Pro tip:</strong> You can subtly weave in your reasons for wanting to work at the company throughout your answers, even when you're not directly asked. For example, when you're asked "Tell me about yourself," you can mention why this particular role excites you and how it connects to your career journey.
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Common Behavioral Questions in Product Manager Interviews</h2>
        <p>
          Here are a few behavioral questions that have been asked at Indian tech companies like Zomato, Flipkart, and Swiggy:
        </p>
        <ul className="bullet-list">
          <li>"Tell me about a decision you made based on instinct."</li>
          <li>"Describe a time when you improved a complex process."</li>
          <li>"Give an example of a time you had to manage a project under tight deadlines."</li>
          <li>"How did you handle a situation where your idea wasn't agreed upon?"</li>
          <li>"Tell me about a time you led a team through a difficult project."</li>
          <li>"Describe how you earn the trust of your teammates."</li>
          <li>"What's the biggest piece of feedback you've received, and how did you handle it?"</li>
        </ul>
        <p>
          These questions can seem vague, but with the right preparation, you can steer them to highlight your strengths and experiences.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">How Can You Prepare?</h2>
        <p>
          Being authentic is the key to success in behavioral interviews. But that doesn't mean you shouldn't prepare. Mapping out your experiences to the company's values will help you communicate clearly while still being yourself.
        </p>
        <p>
          In the next section, we'll teach you how to build a story bank based on the company's values. This will help you prepare for answering behavioral questions with clarity and confidence.
        </p>
      </section>
    </div>
  );
};

export default withCourseNavigation(BehavioralInterviewsPM);
