import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const TechnicalProductQuestionsGuideUpdated = () => {
  return (
    <div className="">
      <h1 className="course-title">How to Answer Technical Product Questions</h1>

      <p className="course-intro">
        In product manager interviews, technical questions are often tailored to the technology and the company. The most common questions you'll face revolve around:
      </p>
      <ul className="bullet-list">
        <li>Designing a system.</li>
        <li>Explaining a technical concept to a non-technical audience.</li>
        <li>Justifying a technical decision you've made in the past.</li>
      </ul>
      <p className="course-intro">
        Let's explore some strategies for tackling these questions.
      </p>

      <section className="interview-section">
        <h2 className="section-title">How to Answer System Design Questions</h2>
        <p>
          System design questions ask you to map out the architecture behind a product. These questions are uncommon for PM interviews, but if you're applying for a technical PM role, your recruiter might notify you in advance.
        </p>
        <p>
          As a product manager, you should focus on user experience and product functionality instead of diving too deep into technical details. Leave the intricate technical explanations to engineers unless prompted otherwise by the interviewer.
        </p>
        <p>
          For example, if you're designing a system, you might mention that a load balancer helps manage traffic spikes to ensure a smooth user experience. However, avoid going into the mechanics of how the load balancer works unless asked.
        </p>
        <p>
          Let's walk through a system design example: "Design an Online Grocery Delivery App."
        </p>

        <h3 className="subsection-title">Step 1: Clarify and Get Context</h3>
        <p>
          Start by clarifying the scope of the question to avoid any assumptions. This ensures you and the interviewer are on the same page.
        </p>
        <p>
          For instance, ask:
        </p>
        <ul className="bullet-list">
          <li>"Should the app include inventory management for local stores?"</li>
          <li>"Is the focus just on delivery, or should it include payment systems as well?"</li>
        </ul>

        <h3 className="subsection-title">Step 2: Identify Key Requirements</h3>
        <p>
          Next, identify the key requirements for the system by walking through a user journey. Break down what the user will do at each step and translate that into system requirements.
        </p>
        <p>
          Example user journey for a grocery delivery app:
        </p>
        <ol className="numbered-list">
          <li>A user opens the app and browses products.</li>
          <li>They add items to the cart.</li>
          <li>They select a delivery slot and complete the payment.</li>
          <li>The app tracks the delivery until it reaches the customer.</li>
        </ol>
        <p>
          From this journey, your requirements might look like:
        </p>
        <ul className="bullet-list">
          <li>A product catalog with real-time stock availability.</li>
          <li>A shopping cart for users to add, modify, or remove items.</li>
          <li>A delivery scheduler to choose a time slot.</li>
          <li>Integration with a payment gateway for smooth transactions.</li>
          <li>A delivery tracking system to keep users updated on their order status.</li>
        </ul>

        <h3 className="subsection-title">Step 3: Define System Attributes</h3>
        <p>
          Think about how the system should behave from a user-centric perspective. For a grocery delivery app, users will expect the system to be reliable, with real-time inventory updates and accurate delivery tracking.
        </p>

        <h3 className="subsection-title">Step 4: Outline Basic Architecture</h3>
        <p>
          Once you've defined the requirements and system attributes, it's time to sketch out a basic system architecture. Focus on the components needed to support your requirements.
        </p>
        <p>
          For the grocery delivery app:
        </p>
        <ul className="bullet-list">
          <li>Product Catalog Service: Displays products with real-time stock availability.</li>
          <li>Cart Management System: Handles user selections and keeps track of items in the cart.</li>
          <li>Order Management System: Manages delivery slots, payment processing, and order tracking.</li>
          <li>Notification System: Sends real-time alerts for order updates and delivery status.</li>
        </ul>

        <h3 className="subsection-title">Step 5: Discuss Trade-offs and Improvements</h3>
        <p>
          End by discussing potential trade-offs and ways to improve the system. For example, you might talk about balancing real-time inventory updates with system performance. Real-time updates could strain system resources, but ensuring users can't order out-of-stock items is critical for a good experience. Consider suggesting a compromise like updating inventory at regular intervals to balance accuracy with performance.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">How to Communicate Technical Concepts</h2>
        <p>
          As a PM, you'll often need to explain technical concepts to non-technical stakeholders, such as marketing teams or customers. These questions test both your technical knowledge and your ability to communicate complex ideas clearly.
        </p>
        <p>
          Let's tackle an example: "Explain API to a 12-year-old."
        </p>

        <h3 className="subsection-title">Step 1: Clarify the Question</h3>
        <p>
          First, clarify who the audience is. In this case, you're explaining to a 12-year-old, so adjust your assumptions accordingly.
        </p>
        <p>
          For example:
        </p>
        <blockquote className="example-quote">
          "I'm assuming the child uses apps or websites regularly but may not know how different apps communicate with each other behind the scenes."
        </blockquote>

        <h3 className="subsection-title">Step 2: List Key Points</h3>
        <p>
          Next, outline the core points you need to communicate:
        </p>
        <ul className="bullet-list">
          <li>An API (Application Programming Interface) allows two apps to talk to each other.</li>
          <li>It's like a waiter in a restaurant: you tell the waiter (API) what you want, and the waiter goes to the kitchen (the app's backend) to get it.</li>
          <li>The waiter (API) brings back the food (data) for you to enjoy.</li>
        </ul>

        <h3 className="subsection-title">Step 3: Use Familiar Concepts</h3>
        <p>
          Find a relatable analogy. For example:
        </p>
        <blockquote className="example-quote">
          "Imagine you're at a restaurant. You want pizza, but you don't go to the kitchen to make it yourself. Instead, you tell the waiter (API) what you want, and they go to the kitchen, get the pizza from the chef (backend), and bring it to you. The waiter is like an API—it helps two things communicate without you needing to know what's happening behind the scenes."
        </blockquote>
        <p>
          By breaking down the concept into familiar terms, you make technical information more accessible.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">How to Explain Technical Decisions</h2>
        <p>
          In some interviews, you may be asked to walk through a technical decision you made in a previous project. The interviewer will assess your understanding of the technical and business trade-offs involved in the decision.
        </p>
        <p>
          For example: "Tell me about a time your team had to choose between two technologies. What trade-offs did you consider?"
        </p>
        <p>
          Use the STAR framework to structure your response:
        </p>
        <ul className="bullet-list">
          <li><strong>Situation:</strong> Briefly describe the project and its goals.</li>
          <li><strong>Task:</strong> Explain the decision at hand, the available options, and their trade-offs.</li>
          <li><strong>Action:</strong> Detail the choice you made, why, and the process behind it.</li>
          <li><strong>Result:</strong> Share the outcome and any lessons learned.</li>
        </ul>
        <p>
          For instance, you might discuss a project where you had to choose between building a custom machine learning model or using an off-the-shelf model. You could explain how your team weighed factors like training time, model accuracy, and deployment speed before making a decision.
        </p>
      </section>
      <p>That's the last module for this series, we are constantly adding new modules. Happy Practicing and All the best for your PM Interview.</p>

    </div>
  );
};

export default withCourseNavigation(TechnicalProductQuestionsGuideUpdated);
