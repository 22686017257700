import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const HowToAnswerEstimationQuestions = () => {
  return (
    <div className="">
      <h1 className="course-title">How to Answer Estimation Questions</h1>

      <section className="intro-section">
        <p>
          Estimation interviews typically last between 40 minutes to an hour. Some interviewers may ask two to three questions with little follow-up, while others may dive deep into a single estimation question for 15 minutes or more.
        </p>
        <p>
          Remember, just like in any other PM interview, the interviewer isn't expecting you to hit the exact answer. The goal is to see how you solve problems with limited information—something you'll face regularly as a product manager.
        </p>
        <p>
          The key to answering ambiguous questions is to break them down into smaller, manageable components. Once you handle each component, you can build them back up to a reasonable final estimation.
        </p>
      </section>

      <div className="pro-tip">
        <h3>Tip</h3>
        <p>
          Learn benchmark facts for India to support your estimations. These could include:
        </p>
        <ul>
          <li>India's population (~1.4 billion)</li>
          <li>The number of smartphone users (~750 million)</li>
          <li>Number of daily train passengers (~23 million)</li>
          <li>India's average life expectancy (~70 years)</li>
        </ul>
      </div>

      <section className="interview-section">
        <h2 className="section-title">Framework for Answering Estimation Questions</h2>
        <p>
          We recommend using a simple 5-step framework for answering estimation questions:
        </p>

        <div className="estimation-step">
          <h3>Step 1: Scope the Problem</h3>
          <p>
            Start by clarifying the scope of the question. This will ensure you're solving the right problem and can prevent misunderstandings. Ask questions that help you focus on what the interviewer is really asking.
          </p>
          <p>
            For example, if you're asked to estimate the number of Ola drivers in Mumbai, you might ask:
          </p>
          <ul className="bullet-list">
            <li>Are we estimating the number of active drivers only or the total number of registered drivers?</li>
            <li>Are we including drivers across all categories (mini, prime, etc.)?</li>
          </ul>
          <p>
            If the interviewer tells you to make assumptions, clearly state those assumptions to guide your approach.
          </p>
        </div>

        <div className="estimation-step">
          <h3>Step 2: Break Down the Problem</h3>
          <p>
            Once you've clarified the problem, break it down into simpler parts. A good way to do this is by creating a high-level formula or framework to work with.
          </p>
          <p>
            For instance, if you're asked to estimate the number of smartphones sold in India this year, you could break it down like this:
          </p>
          <ul className="bullet-list">
            <li>Total population of India ≈ 1.4 billion</li>
            <li>Percentage of people likely to buy smartphones</li>
            <li>Average lifespan of a smartphone (i.e., how often people replace them)</li>
          </ul>
          <p>
            This high-level breakdown helps you create smaller, easier-to-estimate components.
          </p>
        </div>

        <div className="estimation-step">
          <h3>Step 3: Estimate Unknowns</h3>
          <p>
            This step involves estimating values for each component. Even if you don't know exact figures, you can use reasonable assumptions or reference facts you're familiar with.
          </p>
          <p>
            Here are some tips for estimating unknowns:
          </p>
          <ul className="bullet-list">
            <li><strong>Use benchmarks:</strong> Refer to known figures to guide your estimates.</li>
            <li><strong>Estimate using proxies:</strong> For example, you may not know the exact number of grocery stores in Mumbai, but you could use data on the number of households and assume that each neighborhood has a few stores.</li>
            <li><strong>Segment groups:</strong> Don't generalize across diverse groups—break them down where possible. For example, estimating smartphone users in India might require you to separate urban and rural users.</li>
          </ul>
        </div>

        <div className="estimation-step">
          <h3>Step 4: Answer</h3>
          <p>
            Once you've estimated the key numbers, calculate your final answer. It's important to communicate each step of your computation and explain any rounding or simplifying decisions you make along the way.
          </p>
          <p>
            For example, if you're asked to estimate the number of Uber trips in India per day:
          </p>
          <ol className="numbered-list">
            <li>Start by estimating the number of Uber users.</li>
            <li>Multiply that by the average number of trips each user takes per day.</li>
            <li>Adjust for urban vs. rural differences, since Uber operates more frequently in cities.</li>
          </ol>
        </div>

        <div className="estimation-step">
          <h3>Step 5: Explain Why You're Wrong</h3>
          <p>
            Your final answer is likely to be wrong, but that's okay. A great way to show your critical thinking skills is by explaining where your assumptions might be off or what you'd want to revisit if you had more time.
          </p>
          <p>
            For example, after estimating the number of Ola drivers in Mumbai, you might say:
          </p>
          <ul className="bullet-list">
            <li>"I assumed that 1 in 100 people in Mumbai drives for Ola, but this number could be higher or lower based on factors like full-time vs. part-time drivers."</li>
            <li>"I also didn't account for regional variations within the city, where driver density might be higher in central areas compared to the outskirts."</li>
          </ul>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Example: Estimating the Number of Kirana Stores in India</h2>
        <p>
          Let's work through an example to see how this framework works in practice.
        </p>
        <p>
          <strong>Question:</strong> Estimate the number of Kirana stores (small neighborhood grocery shops) in India.
        </p>

        <div className="estimation-example">
          <h3>Step 1: Scope the Problem</h3>
          <ul className="bullet-list">
            <li>Are we considering all types of Kirana stores (e.g., fruit/vegetable sellers, small general stores)?</li>
            <li>Should we focus on urban areas, rural areas, or both?</li>
          </ul>

          <h3>Step 2: Break Down the Problem</h3>
          <p>You might break this question down like this:</p>
          <ul className="bullet-list">
            <li>Start with India's population: ~1.4 billion people.</li>
            <li>Next, estimate the average number of people served by one Kirana store.</li>
            <li>Then, account for the urban-rural divide: urban areas are likely to have fewer people per store compared to rural areas.</li>
          </ul>

          <h3>Step 3: Estimate Unknowns</h3>
          <ul className="bullet-list">
            <li>India's urban population is around 35%, or roughly 490 million people.</li>
            <li>Assume that one Kirana store serves about 500 people in urban areas and 1000 people in rural areas.</li>
            <li>For urban areas: 490 million people ÷ 500 people per store ≈ 980,000 stores.</li>
            <li>For rural areas: 910 million people ÷ 1000 people per store ≈ 910,000 stores.</li>
          </ul>

          <h3>Step 4: Answer</h3>
          <p>
            Adding the urban and rural estimates together gives you approximately 1.89 million Kirana stores across India.
          </p>

          <h3>Step 5: Explain Why You're Wrong</h3>
          <ul className="bullet-list">
            <li>"I assumed that rural stores serve twice as many people as urban stores, but this may vary depending on the region."</li>
            <li>"I also didn't account for organized retail stores, which might reduce the number of Kirana stores in more developed cities."</li>
          </ul>
        </div>
      </section>

      <section className="conclusion-section">
        <h2 className="section-title">Conclusion</h2>
        <p>
          By using this 5-step framework, you can confidently approach any estimation question, whether it's about Ola drivers in Mumbai or Kirana stores in India. The key is to focus on breaking down the problem into manageable parts, making reasonable assumptions, and clearly communicating your thought process. With practice, you'll build confidence in your estimation abilities and be ready to tackle these questions in interviews.
        </p>
      </section>
    </div>
  );
};

export default withCourseNavigation(HowToAnswerEstimationQuestions);