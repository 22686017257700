// CopyProtection.js
import React, { useEffect } from 'react';

const CopyProtection = ({ children }) => {
  useEffect(() => {
    const disableCopy = (e) => {
      e.preventDefault();
      return false;
    };

    const disableContextMenu = (e) => {
      e.preventDefault();
    };

    const disableKeyboardShortcuts = (e) => {
      // Prevent Ctrl+C, Ctrl+X, Ctrl+P, Ctrl+S, Ctrl+Shift+I
      if (
        (e.ctrlKey && (
          e.keyCode === 67 || // C key
          e.keyCode === 88 || // X key
          e.keyCode === 80 || // P key
          e.keyCode === 83 || // S key
          (e.shiftKey && e.keyCode === 73) // Shift + I
        )) ||
        (e.keyCode === 123) // F12
      ) {
        e.preventDefault();
        return false;
      }
    };

    // Add event listeners
    document.addEventListener('copy', disableCopy);
    document.addEventListener('contextmenu', disableContextMenu);
    document.addEventListener('keydown', disableKeyboardShortcuts);

    // Cleanup function
    return () => {
      document.removeEventListener('copy', disableCopy);
      document.removeEventListener('contextmenu', disableContextMenu);
      document.removeEventListener('keydown', disableKeyboardShortcuts);
    };
  }, []);

  return (
    <div 
      style={{
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
      }}
    >
      {children}
    </div>
  );
};

export default CopyProtection;